<template>
  <div class="Paamieshallinta-selaa">
    <!-- Top bar -->
    <ViewSelaaTopBarBase
      pikahaun-placeholder-teksti="Hae päämiestä (nimi, ID)"
      :search-panel="(false)"
      :vuex-module-name="vuexModuleName"
    />

    <ViewSelaaToolbar
      :vuex-module-name="vuexModuleName"
      item-partitiivi="päämiestä"
      :uusi-btn-config="{ click: () => { dialogPaamiesAuki = true }, itemName: 'päämies' }"
    />

    <!-- Show items as table -->
    <v-data-table
      class="v-data-table--striped"
      :headers="tableHeaders"
      :items="items"
      :hide-default-footer="items.length < 10"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50, 100],
      }"
      :multi-sort="true"
      :server-items-length="itemsTotal"
      :options.sync="pagination"
      :loading="loading"
      loading-text=""
      no-data-text=""
      :dense="tiivisNakyma"
    >
      <template v-slot:progress>
        <v-progress-linear
          color="primary"
          indeterminate
        />
      </template>
      <template v-slot:item="{ item }">
        <TableRow
          :clickable="(true)"
          :data="item"
          :fade="loading"
        >
          <td class="text-no-wrap">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <StatusIcon
                  v-if="item.ikoni_ja_vari"
                  :icon="item.ikoni_ja_vari.ikoni"
                  :size="tiivisNakyma ? 'small' : 'medium'"
                  :hover-icon="('open-in-new')"
                  :bg-color="item.ikoni_ja_vari.vari"
                  :event-listener="on"
                  @click="$router.push({ name: 'paamieshallinta-nayta', params: { id: item.id } })"
                />
              </template>
              <template v-slot:default>
                <span>Avaa päämiehen tiedot</span>
              </template>
            </v-tooltip>
          </td>
          <td class="max-width">
            {{ item.nimi }}
          </td>
          <td class="text-no-wrap">
            {{ item.id }}
          </td>
          <td class="text-no-wrap">
            {{ item.tunnus }}
          </td>
          <td class="text-no-wrap">
            {{ item.tyyppi }}
          </td>
        </TableRow>
      </template>
    </v-data-table>

    <TyhjaTaulukkoOtsikko v-if="tyhjaTaulukko"
      :otsikko="$t('youHaveNoCreditors')"
    >
    </TyhjaTaulukkoOtsikko>
    <ToolbarTyhjaTaulukko v-if="tyhjaTaulukko"
      ikoni="library_add"
      taustavari="success"
      :teksti="$t('newCreditor')"
      :infoteksti="$t('noCreditorAddInfo')"
      :naytaAina="true"
      @click="dialogPaamiesAuki = true"
    >
    </ToolbarTyhjaTaulukko>
    <!-- Pagination -->
    <v-toolbar
      v-if="(pagesTotal > 1)"
      class="my-6 py-2 transparent"
      flat
    >
      <v-spacer />
      <Pagination
        v-model="pagination.page"
        :length="pagesTotal"
        :disabled="loading"
      />
      <v-spacer />
    </v-toolbar>

    <!-- Dialog: Uusi päämies -->
    <CardDialog
      title="Uusi päämies"
      :dialog-auki="dialogPaamiesAuki"
      title-class="px-12 py-6"
      content-class="px-12 py-4"
      width="1024"
      @close="(dialogPaamiesAuki = false)"
    >
      <FormPaamies
        :on-auki="dialogPaamiesAuki"
        @close="(dialogPaamiesAuki = false)"
      />
    </CardDialog>
  </div>
</template>

<script>

import {
  FormPaamies,
  Pagination,
  ToolbarTyhjaTaulukko,
  TyhjaTaulukkoOtsikko,
  ViewSelaaToolbar,
  ViewSelaaTopBarBase,
} from '@/components'
import ViewSelaaMixin from '@/mixins/ViewSelaaMixin'
import { TableHeadersPaamieshallinta } from '@/utils/tableHeaders'

export default {
  name: 'PaamieshallintaSelaa',
  components: {
    FormPaamies,
    Pagination,
    ToolbarTyhjaTaulukko,
    TyhjaTaulukkoOtsikko,
    ViewSelaaToolbar,
    ViewSelaaTopBarBase,
  },
  mixins: [ViewSelaaMixin],
  data () {
    return {
      dialogPaamiesAuki: false,
      tableHeaders: TableHeadersPaamieshallinta,
    }
  },
}

</script>

<style lang="scss" scoped>

</style>
