var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Paamieshallinta-selaa" },
    [
      _c("ViewSelaaTopBarBase", {
        attrs: {
          "pikahaun-placeholder-teksti": "Hae päämiestä (nimi, ID)",
          "search-panel": false,
          "vuex-module-name": _vm.vuexModuleName,
        },
      }),
      _c("ViewSelaaToolbar", {
        attrs: {
          "vuex-module-name": _vm.vuexModuleName,
          "item-partitiivi": "päämiestä",
          "uusi-btn-config": {
            click: function () {
              _vm.dialogPaamiesAuki = true
            },
            itemName: "päämies",
          },
        },
      }),
      _c("v-data-table", {
        staticClass: "v-data-table--striped",
        attrs: {
          headers: _vm.tableHeaders,
          items: _vm.items,
          "hide-default-footer": _vm.items.length < 10,
          "footer-props": {
            itemsPerPageOptions: [10, 25, 50, 100],
          },
          "multi-sort": true,
          "server-items-length": _vm.itemsTotal,
          options: _vm.pagination,
          loading: _vm.loading,
          "loading-text": "",
          "no-data-text": "",
          dense: _vm.tiivisNakyma,
        },
        on: {
          "update:options": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "progress",
            fn: function () {
              return [
                _c("v-progress-linear", {
                  attrs: { color: "primary", indeterminate: "" },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "item",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "TableRow",
                  { attrs: { clickable: true, data: item, fade: _vm.loading } },
                  [
                    _c(
                      "td",
                      { staticClass: "text-no-wrap" },
                      [
                        _c("v-tooltip", {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    item.ikoni_ja_vari
                                      ? _c("StatusIcon", {
                                          attrs: {
                                            icon: item.ikoni_ja_vari.ikoni,
                                            size: _vm.tiivisNakyma
                                              ? "small"
                                              : "medium",
                                            "hover-icon": "open-in-new",
                                            "bg-color": item.ikoni_ja_vari.vari,
                                            "event-listener": on,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$router.push({
                                                name: "paamieshallinta-nayta",
                                                params: { id: item.id },
                                              })
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                },
                              },
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("span", [
                                      _vm._v("Avaa päämiehen tiedot"),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                    _c("td", { staticClass: "max-width" }, [
                      _vm._v(" " + _vm._s(item.nimi) + " "),
                    ]),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(" " + _vm._s(item.id) + " "),
                    ]),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(" " + _vm._s(item.tunnus) + " "),
                    ]),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(" " + _vm._s(item.tyyppi) + " "),
                    ]),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _vm.tyhjaTaulukko
        ? _c("TyhjaTaulukkoOtsikko", {
            attrs: { otsikko: _vm.$t("youHaveNoCreditors") },
          })
        : _vm._e(),
      _vm.tyhjaTaulukko
        ? _c("ToolbarTyhjaTaulukko", {
            attrs: {
              ikoni: "library_add",
              taustavari: "success",
              teksti: _vm.$t("newCreditor"),
              infoteksti: _vm.$t("noCreditorAddInfo"),
              naytaAina: true,
            },
            on: {
              click: function ($event) {
                _vm.dialogPaamiesAuki = true
              },
            },
          })
        : _vm._e(),
      _vm.pagesTotal > 1
        ? _c(
            "v-toolbar",
            { staticClass: "my-6 py-2 transparent", attrs: { flat: "" } },
            [
              _c("v-spacer"),
              _c("Pagination", {
                attrs: { length: _vm.pagesTotal, disabled: _vm.loading },
                model: {
                  value: _vm.pagination.page,
                  callback: function ($$v) {
                    _vm.$set(_vm.pagination, "page", $$v)
                  },
                  expression: "pagination.page",
                },
              }),
              _c("v-spacer"),
            ],
            1
          )
        : _vm._e(),
      _c(
        "CardDialog",
        {
          attrs: {
            title: "Uusi päämies",
            "dialog-auki": _vm.dialogPaamiesAuki,
            "title-class": "px-12 py-6",
            "content-class": "px-12 py-4",
            width: "1024",
          },
          on: {
            close: function ($event) {
              _vm.dialogPaamiesAuki = false
            },
          },
        },
        [
          _c("FormPaamies", {
            attrs: { "on-auki": _vm.dialogPaamiesAuki },
            on: {
              close: function ($event) {
                _vm.dialogPaamiesAuki = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }